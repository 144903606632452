export const INTENTS = {
  REMOVE_FROM_SHOPPING_CART: "remove_from_shopping_cart",
  INCREASE_QUANTITY: "increase_quantity",
  DECREASE_QUANTITY: "decrease_quantity",
  ADD_TO_WAITING_LIST: "add_to_waiting_list",
  ADD_TO_CART: "add_to_cart",
  ADD_CROSS_SELL_TO_CART: "add_cross_sell_to_cart",
  EMAIL_SIGNUP: "email_signup",
  EMAIL_SIGNUP_FOR_SALE: "email_signup_for_sale",
  EMAIL_SIGNUP_FOR_HOLIDAY_CALENDAR: "email_signup_for_holiday_calendar",
};
