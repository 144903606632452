import { BigButton } from "../BigButton";
import { formatPrice } from "~/utils/strings";
import {
  FetcherWithComponents,
  useFetcher,
  useLoaderData,
  useNavigate,
} from "@remix-run/react";
import { CloseIcon } from "../../assets/icons/Close";
import { Children, ReactNode, useEffect, useRef, useState } from "react";
import { useModal } from "../../context/Modal";
import type { Cart } from "../../../../types/ShoppingCart";
import { calculateTotalPrice } from "~/utils/price";
import { useCloseOnClickOutside } from "~/hooks/useCloseOnClickOutside";
import { GetSmallUrl } from "~/utils/images";
import { INTENTS } from "~/utils/intents";
import type { CartItem } from "../../../../types/CartItem";

export const RightPanelWrapper = ({
  isVisible,
  onClickOutside,
  children,
}: {
  isVisible: boolean;
  onClickOutside: () => void;
  children: ReactNode;
}) => {
  const [opacity, setOpacity] = useState("opacity-0");
  const [hidden, setHidden] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useCloseOnClickOutside(() => {
    onClickOutside();
  }, ref);

  useEffect(() => {
    if (isVisible) {
      setOpacity("opacity-100");
      setHidden(false);
    } else {
      setOpacity("opacity-0");
      setTimeout(() => {
        setHidden(true);
      }, 300);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={`fixed right-0 top-0 h-full w-[400px] bg-white px-6 shadow-2xl transition-opacity duration-300 ${opacity} ${
        hidden ? "-z-10" : "z-30"
      }`}
    >
      {children}
    </div>
  );
};
